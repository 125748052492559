import type { AlertViewModel } from './alerts'
import type { JobDataApiModel, JobDataModel } from './jobdatainfo'

export enum NotificationMode {
  IN_APP,
  PUSH,
  SMS,
  EMAIL,
}

export enum NotificationState {
  NEW,
  TO_SEND,
  SENT,
  READ,
  DELETED,
}

export enum NotificationFilter {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  ALL = 'all',
}

export enum NotificationType {
  ALERT = 'alert',
  REMINDER = 'reminder',
  ROUTINE = 'routine',
  QUALIFY_MACHINE = 'qualify_machine',
}

export type NotificationTypes = Lowercase<keyof typeof NotificationType>

export interface NotificationStatus {
  mode: NotificationMode
  state: NotificationState
  readAt: Date | null
}

export interface NotificationSettings {
  type: NotificationType
  enabled: boolean
}

export interface NotificationCellInfo {
  row: string | number
  col: string | number
}

export interface NotificationReportInfo {
  id: number
  name: string
  routine_date?: string
  routine_hours?: []
  workplace_id: string
  document_id?: string
}

/* export type NotificationViewRawReminderData = {}

export type NotificationApiRawReminderData = {
  [K in keyof NotificationViewRawReminderData as SnakeToCamelCase<K>]: NotificationViewRawReminderData[K]
}

export type NotificationViewRawAlertData = {}

export type NotificationApiRawAlertData = {
  [K in keyof NotificationViewRawReminderData as SnakeToCamelCase<K>]: NotificationViewRawAlertData[K]
} */

/* export interface NotificationViewRawModel {
  id: string
  alertId?: string
  assigneeId: string
  path: string
  date: number | Date
  type: NotificationType
  cellInfo: NotificationCellInfo
  reportInfo: NotificationReportInfo
  status: NotificationStatus
}

export interface NotificationApiRawModel {
  id: string
  alert_id?: string
  assignee_id: string
  path: string
  date: number | Date
  type: NotificationType | null
  cell_info: NotificationCellInfo
  report_info: NotificationReportInfo
  status: NotificationStatus
} */

interface NotificationAlertViewData {
  alertId: string
  alert: AlertViewModel
}

interface NotificationReminderViewData {
  path: string
  step: string | number
  reportInfo: NotificationReportInfo
  cellInfo: NotificationCellInfo
}

export type NotificationViewRawModel = {
  id: string
  type: NotificationType
  assigneeId: string
  date: number | Date
  status: NotificationStatus
  cells: Array<any>
} & Partial<NotificationAlertViewData> &
  Partial<NotificationReminderViewData>

interface NotificationAlertApiData {
  alert_id: string
}

interface NotificationReminderApiData {
  path: string
  cell_info: NotificationCellInfo
  report_info: NotificationReportInfo
  cells: Array<object>
}

export type NotificationApiRawModel = {
  id: string
  type: NotificationType
  assignee_id: string
  date: number | Date
  status: NotificationStatus
  cells: Array<any>
} & Partial<NotificationAlertApiData> &
  Partial<NotificationReminderApiData>

export interface NotificationViewModel
  extends NotificationViewRawModel,
    JobDataModel {}
export interface NotificationApiModel
  extends NotificationApiRawModel,
    JobDataApiModel {}

export class NotificationModelMapper {
  static notificationModelToApiModel(
    notificationModel: NotificationViewRawModel,
  ): NotificationApiRawModel {
    const notification = {
      id: notificationModel.id,
      alert_id: notificationModel.alertId,
      assignee_id: notificationModel.assigneeId,
      path: notificationModel.path,
      date: notificationModel.date,
      type: notificationModel.type,
      cell_info: notificationModel.cellInfo,
      report_info: notificationModel.reportInfo,
      status: notificationModel.status,
      cells: notificationModel.cells,
    }

    Object.keys(notification).forEach(
      (key) => notification[key] === undefined && delete notification[key],
    )

    return notification
  }
}
