export enum NotificationMode {
  IN_APP,
  PUSH,
  SMS,
  EMAIL,
}

export enum NotificationState {
  NEW,
  TO_SEND,
  SENT,
  READ,
}

export enum NotificationFilter {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  ALL = 'all',
}

export enum NotificationType {
  ALERT = 'alert',
  REMINDER = 'reminder',
  ROUTINE = 'routine',
  QUALIFY_MACHINE = 'qualify_machine',
}

export type NotificationTypes = Lowercase<keyof typeof NotificationType>

export interface NotificationStatus {
  mode: NotificationMode
  state: NotificationState
}

export interface NotificationSettings {
  type: NotificationType
  enabled: boolean
}

export interface NotificationCellInfo {
  row: string | number
  col: string | number
}

export interface NotificationReportInfo {
  id: string
  name: string
  routine_date?: string
  routine_hours?: []
  workplace_id: string
}

export class JNotification {
  id = ''
  client_id = ''
  site_id = ''
  created_by = ''
  assignee_id = ''
  path = ''
  created_date: number | Date = 0
  date = 0
  type: NotificationType | null = null
  cellInfo: NotificationCellInfo = {} as NotificationCellInfo
  reportInfo: NotificationReportInfo = {} as NotificationReportInfo
  status: NotificationStatus = {
    mode: NotificationMode.IN_APP,
    state: NotificationState.NEW,
  }

  constructor(object: JNotification | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}
