import api from '~/helpers/ApiHelper'
import { DateTime } from 'luxon'

class RecordDataController {
  async getRecordData(payload) {
    const recordData = await api.getRecordData(payload)

    return recordData.map((e) => {
      const dt = DateTime.fromISO(e.measured_at, {
        zone: payload.timezone,
      })
      const formatString = 'yyyy-MM-dd HH:mm:ss'
      return {
        ...e,
        measured_at: dt.toFormat(formatString),
      }
    })
  }

  async checkNCValues(payload) {
    return await api.checkNCValues(payload)
  }

  async last24HoursDevices(payload = {}) {
    return await api.last24HoursDevices(payload)
  }
}

const recordDataController = new RecordDataController()

export default recordDataController
