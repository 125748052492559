import { DocumentTrigger } from './jDocument'
import type { JDocument } from './jDocument'
import type { JStep } from './jStep'

export enum ReportStatus {
  new = 1,
  in_progress,
  finished,
}

export enum RoleGrid {
  editor = 'editor',
  preview = 'preview',
  conceptor = 'conceptor',
}

export enum OFType {
  newTask = 'newTask',
  view = 'view',
  continueTask = 'continueTask',
}

export class JStatusHistory {
  updated_by = ''
  update_date = ''
  status = ''

  constructor(object: JStatusHistory | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export interface JReportContext {
  workplace_id?: string
  product_id: string
  operation_id: string
  routine_date?: number
  root_version_document_id?: string[]
}

export class JReport {
  id = ''
  document_id = ''
  document_category = ''
  document_name = ''
  document_trigger: DocumentTrigger = DocumentTrigger.production_order
  is_multiple = false
  is_valid = true

  steps: JStep[] = []
  pinned_column_index: number | null = null

  // Insights
  controlTotal = 0
  controlSuccess = 0
  processSuccess = 0

  // Context
  session_id = ''
  workplace = ''
  status = ReportStatus.new
  status_history = [] as JStatusHistory[]
  last_team = ''
  last_col_index = 0
  gridSize = 1 // Migrate to grid_size
  initialGridSize = 1
  context = {} as any

  // Audit
  creation_date: any = ''
  created_by = ''
  update_date: any = ''
  updated_by = ''

  // tenant ownership
  client_id = ''
  site_id = ''

  constructor(object: JDocument) {}
}

export class TaskInputData {
  id = ''
  column_id = 0
  row_id = 0

  session_id = ''
  report_id = ''
  step_id = ''

  data = {}
  update_history = [] as InputAudit[]

  constructor(object: TaskInputData | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class InputAudit {
  updated_by = ''
  updated_date = ''

  constructor(object: InputAudit | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class JTarget {
  init_col_id = ''
  last_col_id = ''
  target = 0

  constructor(object: JTarget | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}
