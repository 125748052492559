import { defineStore } from 'pinia'
import type { JDocument } from '~/models/documents/jDocument'
import type { JStep } from '~/models/documents/jStep'
export const documentStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'document',
  // a function that returns a fresh state
  state: () => ({
    document: {} as JDocument,
    routines: {} as JDocument[],
  }),

  // optional getters
  getters: {
    getDocument: (state) => state.document,
    getRoutines: (state) => state.routines,
    getSteps: (state) => state.document?.steps, // ?.sort((a, b) => (a.num_step > b.num_step) ? 1 : -1),
  },
  // optional actions
  actions: {
    setDocument(obj: object) {
      this.document = Object.assign(this.document, obj)
    },
    setRoutines(routines: JDocument[]) {
      this.routines = [...routines]
    },
    setSteps(steps: JStep[]) {
      this.document.steps = steps
    },
    resetDocument() {
      this.document = {} as JDocument
    },
    changeOrderStep(direction: string, index: number) {
      if (direction === 'up') {
        if (index === 0) return
        const temp = this.document.steps[index - 1].num_step
        this.document.steps[index - 1].num_step =
          this.document.steps[index].num_step
        this.document.steps[index].num_step = temp
        this.document.steps.sort((a, b) => (a.num_step > b.num_step ? 1 : -1))
      } else if (direction === 'down') {
        if (index === this.document.steps.length - 1) return
        const temp = this.document.steps[index + 1].num_step
        this.document.steps[index + 1].num_step =
          this.document.steps[index].num_step
        this.document.steps[index].num_step = temp
        this.document.steps.sort((a, b) => (a.num_step > b.num_step ? 1 : -1))
      }
    },
  },
})
