import _, { chain, memoize } from 'lodash'
import { createPopper } from '@popperjs/core'
import localforage from 'localforage'
import { StepType } from '~/models/documents/jStep'
import { gS } from '~/store/global'
import { Icons } from '~/models/Icon'

export const oAssign = (from: any, value: any) => {
  for (const a in from) {
    if (value[a]) from[a] = value[a]
  }
}

const KEY = 'messages'

export const clearMessages = () => localStorage.removeItem(KEY)

export const clearCacheStorage = async () => {
  if (window.caches) {
    await Promise.all(
      chain(await window.caches.keys())
        .map((key) => window.caches.delete(key))
        .value(),
    )
  }
}

export const getOrdinal = (occurrence: number, t: any) => {
  switch (occurrence) {
    case 1:
      return t('days.first')
    case 2:
      return t('days.second')
    case 3:
      return t('days.third')
    case 4:
      return t('days.fourth')
    default:
      return `${occurrence}th`
  }
}

export const waitForElm = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect()
        resolve(document.querySelector(selector))
      }
    })

    // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

export const clearDataCache = async () => {
  await localforage.clear()
}

export const forceReload = (redirectToHome?: boolean) => {
  const { protocol, hostname, port } = window.location
  const url = [protocol, '//', hostname]
  if (port) url.push(...[':', port])

  fetch(url.join(''), {
    cache: 'reload',
    headers: { 'Clear-Site-Data': 'storage' },
  }).finally(() => {
    redirectToHome ? (window.location.href = '/') : window.location.reload()
  })
}

export function stringToSlug(str: string) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++)
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
  return str
}

export const hotReload = async (
  payload: { redirectToHome: boolean } | undefined,
) => {
  await clearCacheStorage()
  await clearDataCache()
  clearMessages()
  forceReload(payload?.redirectToHome)
}

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

export const randomAvatarColor = () => {
  // const colors = ['[#DD1534]', '[#FFDD4F]', '[#3C7E1C]', '[#D77AED]', '[#ED772F]', '[#A2ABE4]']
  const colors = ['[#FEF0CD]', '[#E2EEC0]']

  return colors[Math.floor(Math.random() * colors.length)]
}

export const titleToCamelCase = (str: string) =>
  str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export const versionToNumber = memoize((version?: string) =>
  chain(version)
    .defaultTo(0)
    .thru(String)
    .replace(/\./g, '')
    .join('')
    .thru(Number)
    .value(),
)

export const castInputDataValue = (data: any) => {
  if (data.type === StepType.Boolean) {
    if (data.value === 'true') return 'Ok'
    if (data.value === 'false') return 'NOk'
    if (data.value === 'NA') return 'NA'
  }
  if (data.type === StepType.Checkbox)
    return data.value === null ? null : data.value === 'true' || data.value
  else if (
    (data.type === StepType.List || data.type === StepType.Photo) &&
    typeof data.value === 'string'
  )
    return data.value?.split('"').filter((e) => /\w/.test(e))

  return data.value
}

export const getDoubleCheckIcon = (result) => {
  if (result === false) return Icons.WARNING
  if (result) return Icons.CHECKMARK_DONE
  return Icons.CHECKMARK
}

export const genPassword = () => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const passwordLength = 16
  let password = ''
  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length)
    password += chars.substring(randomNumber, randomNumber + 1)
  }
  return password
}

export const getLastUpdatedByOnCell = (params: any) => {
  const answers =
    params?.colDef?.answers?.filter(
      (answer) => answer.row_id === params?.rowIndex,
    ) || []
  const lastUpdatedBy = _.first(
    _.orderBy(answers, 'update_date', 'desc'),
  )?.updated_by

  return lastUpdatedBy
}

export const currentLangIsFrench = (): boolean => {
  const { locale } = useI18n()

  if (locale.value === 'fr') return true

  return false
}

export const currentLangIsEnglish = (): boolean => {
  const { locale } = useI18n()
  if (locale.value === 'en') return true

  return false
}

export const displayDropdownOnTop = (dropdownList, component, { width }) => {
  dropdownList.style.width = width

  const popper: any = createPopper(component.$refs.toggle, dropdownList, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top')
        },
      },
    ],
  })

  return () => popper.destroy()
}

export const capitalizeFirstLetter = (label: string) => {
  return label.charAt(0).toUpperCase() + label.slice(1)
}

export const filterOptionByIdOrName = (input: string, option: any) => {
  const idMatch = option?.id?.toLowerCase().includes(input.toLowerCase())
  const nameMatch = option?.name?.toLowerCase().includes(input.toLowerCase())
  return idMatch || nameMatch
}

export const filterChilds = (object: any) => {
  const parentIds = _.uniq(object.map((p) => p.parentId).filter(Boolean))
  return object.filter((p) => !parentIds.includes(p.id))
}

export const findElementAndParentById = (array, id, parent = null) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return {
        element: array[i],
        parent,
      }
    } else if (array[i].children) {
      const resultInChildren = findElementAndParentById(
        array[i].children,
        id,
        array[i],
      )

      if (resultInChildren) return resultInChildren
    }
  }
  return null
}

export const checkInactivity = (
  inactivityLimit: number,
  refetch: () => void,
) => {
  const lastActivity = gS().lastActivity
  const currentTime = new Date()
  const lastActivityTime = new Date(lastActivity)
  const inactivityDuration =
    (currentTime.getTime() - lastActivityTime.getTime()) / 1000

  if (inactivityDuration >= inactivityLimit) {
    refetch()
  }
}
