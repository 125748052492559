import { hydrate } from '~/utils/object'
import type { Nullable } from '~/utils/nullable'
import { JStep } from '~/models/documents/jStep'

export type TDocumentGroupBy =
  | 'status'
  | 'workplaces'
  | 'products'
  | 'categories'
  | 'templates'
  | 'operations'
  | 'no_grouping'

export enum Days {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  ALL = 7,
}

export enum DocumentListType {
  DOCUMENTS = 'documents',
  TEMPLATES = 'templates',
  SIMULATION = 'simulation',
}

export enum DocumentStatus {
  new = 1,
  in_progress,
  finished,
  draft,
  published,
  archived,
  deleted,
}

export enum DocumentState {
  new = 'new',
  upgrade = 'upgrade',
}

export enum DocumentType {
  readonly = 'readonly',
  read_write = 'read_write',
  automatic = 'automatic',
}

export enum DisplayColumnType {
  all_columns = 'all_columns',
  editable_columns = 'editable_columns',
}

export enum DisplayLineType {
  all_lines = 'all_lines',
  editable_lines = 'editable_lines',
}

export enum ApplicationFieldType {
  product = 'product',
  workplace = 'workplace',
  operation = 'operation',
}

export enum ApplicationFieldAllType {
  ALL_PRODUCTS = 'ALL_PRODUCTS',
  ALL_WORKPLACES = 'ALL_WORKPLACES',
  ALL_OPERATIONS = 'ALL_OPERATIONS',
}

export enum ApplicationFieldSearchType {
  SEARCH_PRODUCTS = 'SEARCH_PRODUCTS',
  SEARCH_WORKPLACES = 'SEARCH_WORKPLACES',
  SEARCH_OPERATIONS = 'SEARCH_OPERATIONS',
}

export enum DocumentTrigger {
  // team = 'team',
  // day = 'day',
  production_order = 'production_order',
}

export enum DocumentRole {
  quality = 'quality',
  routine = 'routine',
  workplace = 'workplace',
}

export enum DocumentFormat {
  Unique = 'Unique',
  Quantity = 'Quantity',
  Fixed = 'Fixed',
  Unlimited = 'Unlimited',
}

export class ApplicationField {
  id = ''
  type: ApplicationFieldType = ApplicationFieldType.product
  label?: string
}

export enum FrequencyType {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
}

export enum RecurrenceType {
  no_schedule = 'no_schedule',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export enum RecurrenceMonthMethod {
  date = 'date',
  xDayOfMonth = 'xDayOfMonth',
}

export class RecurrencePeriod {
  recurrence_starting_date: string | null | number | Date = null
  recurrence_hours: { from: string; to: string }[] = []
  recurrence_hours_enabled = false
  recurrence_weekday_of_the_month = 0
  recurrence_weekday_position = 0
  recurrence_month_method = ''

  constructor(object: RecurrencePeriod | object = {}) {
    for (const key in object) {
      this[key] = object[key]
    }
  }
}

export class RecurrencePeriodViewModel {
  recurrence_starting_date: string | null | number | Date = null
  recurrence_hours: { from: string; to: string }[] = []
  recurrence_hours_enabled = false
  recurrence_weekday_of_the_month = 0
  recurrence_weekday_position = 0
  recurrence_month_methods: { label: string; value: string }[] = []
  recurrence_month_method = RecurrenceMonthMethod.xDayOfMonth

  constructor(object: RecurrencePeriodViewModel | object = {}) {
    for (const key in object) {
      this[key] = object[key]
    }
  }
}

export class Schedule {
  days = [] as Days[]
  recurrenceType?: RecurrenceType = RecurrenceType.day
  recurrence?: Nullable<number> = 1
  recurrencePeriods: RecurrencePeriodViewModel[] = [
    new RecurrencePeriodViewModel(),
  ]

  constructor(object: Schedule | object = {}) {
    for (const key in object) {
      this[key] = object[key]
    }
  }
}

export class Frequency {
  enabled = false
  type: FrequencyType | null = null
  every: number | null = null
  startingAt = ''
  enableNotification = false
  startWithReportLaunch = true

  constructor(object: Frequency | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export enum FrequencyType {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
}

export class RolesAcces {
  label = ''
  value = 0

  constructor(object: Frequency | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export interface DuplicateDocumentOptions {
  siteId: string
  translateLang: string
  newVersion?: boolean
}

export class DocumentCategory {
  blocker = false
  justify_ko = false
  mandatory_finish = false
  order = 0
  roles_acces = [] as RolesAcces[]
  client_id = ''
  site_id = ''
  value = ''

  // Audit
  creation_date: any = ''
  update_date: any = ''
  deleted_at = ''

  type: string | null = null

  constructor(object: Frequency | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class JDocument {
  // permet d'ajouter à la volé uniquement en dehors de l'object, ex: document.AttrInvente = valIncroyable
  [x: string]: any

  // General
  id = ''
  name = ''
  description = ''
  displayType: DisplayColumnType = DisplayColumnType.all_columns
  displayLineType: DisplayLineType = DisplayLineType.all_lines
  status: DocumentStatus = DocumentStatus.draft
  type: DocumentType = DocumentType.read_write
  client_id = ''
  site_id = ''
  is_multiple = false
  trigger: DocumentTrigger = DocumentTrigger.production_order
  role: DocumentRole = DocumentRole.quality
  format = {
    type: DocumentFormat.Unique,
    value: null,
    formula: null,
  }

  attachment_id = ''
  attachment_ids = []

  // Content
  steps: Array<string | JStep> = [] // convert
  applicationfields: Array<ApplicationField> = []
  templates: Array<string> = []
  workflow_id = ''

  // Parameters
  category = ''
  order = 1
  scheduling = ''
  schedule: Schedule = new Schedule()

  is_monocolumn = false

  frequency: Frequency = {
    enabled: false,
    type: null,
    every: null,
    startingAt: '',
    enableNotification: false,
    startWithReportLaunch: true,
  }

  starting_date: any = ''
  expiration_date: any = ''
  grid_header: any = ''

  // NIR
  device_id = ''
  device_name = ''
  // Audit
  creation_date: any = ''
  update_date: any = ''
  created_by = ''
  updated_by = ''

  // Versioning
  version = '1.0'
  version_reason = ''
  root_version_document_id = ''
  next_version_document_id?: string = ''

  constructor(object: typeof JDocument | {}) {
    hydrate(this, object)
  }
}
