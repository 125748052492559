import { usersStore } from '~/store/users'
import _ from 'lodash'
import { defineStore } from 'pinia'
import SiteController from '~/controllers/sites'
import StatusController from '~/controllers/statuses'
import type { JSite } from '~/models/sites'
import { workflowStore } from '~/store/workflows'
import {
  NON_BUILT_IN_STATUSES_IDS_THRESHOLD,
  WorkflowType,
} from '~/components/Settings/Workflows/config'
import {
  BuiltInStatus,
  ReportStatus,
  type StatusRawModel,
} from '~/common/models/status'
import { SITES_COLLECTION_NAME } from '~/common/config/firebase/storage'
import DBHelper from '~/helpers/dbHelper'
import { USERS_COLLECTION_NAME } from '~/config/storage'

export const siteStore = defineStore({
  id: 'site',
  state: () => ({
    site: {} as JSite,
    userSites: {} as JSite[],
    clientSites: {} as JSite[],
    availableSiteStatuses: [] as any,
  }),
  getters: {
    allNonDeletedStatuses(state) {
      return state.availableSiteStatuses.filter(
        (e) =>
          e.id !== BuiltInStatus.select &&
          e.id !== BuiltInStatus.deleted &&
          !e.deletedAt,
      )
    },
    siteId(state) {
      return state.site?.id
    },
    allReportsStatuses(state) {
      return state.availableSiteStatuses?.filter(
        (e) =>
          Object.values(ReportStatus).filter(Number).includes(e.id) ||
          e.id >= NON_BUILT_IN_STATUSES_IDS_THRESHOLD,
      )
    },
    isTemplateEnabled(state) {
      return !state.site.flags?.hide_templates
    },
  },
  actions: {
    async loadSite(): Promise<any> {
      const user = usersStore().user
      const sites: JSite[] = await SiteController.getAllSites()
      const userSites = sites?.filter((site) =>
        user.site_ids?.includes(site.id),
      )
      const siteStatuses = await StatusController.getStatuses()

      const currentSite =
        sites.find((site) => site.id === user.site_id) ||
        (await SiteController.getCurrentSite())[0]

      const dbHelper = new DBHelper()
      await dbHelper?.getDocFromCollectionOnSnapshot(
        SITES_COLLECTION_NAME,
        currentSite.id,
        (site: JSite) => {
          this.site = site
        },
      )

      this.site = currentSite
      this.clientSites = sites
      this.userSites = userSites
      this.availableSiteStatuses = siteStatuses
    },
    getStatus(statusId) {
      return this.availableSiteStatuses?.find((e) => e.id === statusId)
    },
    getFlag(flagName) {
      return this.site.flags?.[flagName]
    },
    findSiteByIds(siteIds: string[]) {
      return this.clientSites?.filter((e) => siteIds?.includes(e.id)) || []
    },
    async setCurrentSite(siteId: string) {
      const user = usersStore().user
      const dbHelper = new DBHelper()
      return await dbHelper?.updateDataToCollection(
        USERS_COLLECTION_NAME,
        user.id,
        { site_id: siteId },
      )
    },
    hasActions(): boolean {
      return this.site?.flags?.actions?.some((e) => e.enabled)
    },
    hasFlag({ flagField, flagType }): boolean {
      return this.site?.flags
        ? this.site?.flags[flagField]?.some(
            (p: any) => p.type === flagType && p.enabled,
          )
        : false
    },
    productionOrderStatuses() {
      let result: StatusRawModel[] = []
      const allStatuses = this.availableSiteStatuses
      const productionOrderStatuses =
        workflowStore().findWorkflowByType(WorkflowType.PRODUCTION_ORDERS)
          ?.steps ?? []
      if (!_.isEmpty(productionOrderStatuses)) {
        result = productionOrderStatuses.map((e) => {
          return allStatuses.find((status) => status.id === e.status)
        })
      } else {
        result = allStatuses.filter((e) => {
          if (
            e.id === ReportStatus.new ||
            e.id === ReportStatus.in_progress ||
            e.id === ReportStatus.finished
          )
            return e
        })
      }
      return result
    },
    reportStatuses() {
      let result: StatusRawModel[] = []
      const allStatuses = this.availableSiteStatuses
      const productionOrderStatuses =
        workflowStore().findWorkflowByType(WorkflowType.REPORTS)?.steps ?? []
      if (!_.isEmpty(productionOrderStatuses)) {
        result = productionOrderStatuses.map((e) => {
          return allStatuses.find((status) => status.id === e.status)
        })
      } else {
        result = allStatuses.filter((e) => {
          if (
            e.id === ReportStatus.new ||
            e.id === ReportStatus.in_progress ||
            e.id === ReportStatus.finished
          )
            return e
        })
      }
      return result
    },
  },
})
